<template>
    <div>
        <vModal v-if="!isActive" type="opacity" :disableMaskClick="true" clickClass='pass-btn'>
            <vButton class="pass-btn" :text="getPassBtnText" @btnClick="getPass" />
        </vModal>
        <vModal v-if="isActive" :disableMaskClick="true" clickClass='open-pass-btn' @maskClick="jumpTo">
            <div slot="header">
                <div class="overlay-confirm"><img :src="SUCCESS_SRC" class="success-img"></div>
            </div>
            <div slot="footer" class="modal-overlay-footer">
                <vButton class="open-pass-btn" text="Open Minipass" @btnClick="jumpTo" />
            </div>
            <div class="overlay-main">
                <div class="info">Great!</div>
                <div class="info">Now you can use this pass.</div>
            </div>
        </vModal>

        <div class="card-container">
            <div class="header-wrapper" style="margin-top: 20px;">
                <div class="title">{{sInfo.sname}}</div>
                <div class="info">{{sInfo.sdesc}}</div>
            </div>
            <div class="center-btn">Loyalty card</div>
            <div class="point-wrapper">
                <div class="card-wrapper">
                    <!-- <div class="header-wrapper" :style="{backgroundImage: 'url('+ sInfo.spic +')', backgroundRepeat:'no-repeat',
                    backgroundSize:'100% 100%' }"></div> -->
                    <div class="qrcode" ref="qrCodeUrl"></div>
                    <div class="point-wrapper" v-if="false">
                        <span class="point">{{point}}</span>
                        <span class="unit">pts</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<style scoped>
    .card-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #FAF7F7;
        overflow: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .card-container .header-wrapper {
        width: 375px;
        height: 106px;
        background-color: #F2F2F2;
        text-align: -webkit-center;
        color: #000;
    }

    .header-wrapper .title {
        font-size: 24px;
        font-weight: bold;
        line-height: 30px;
        max-width: 200px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .header-wrapper .info {
        font-size: 12px;
        opacity: .8;
        line-height: 14px;
        max-width: 200px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-top: 12px;
    }

    .header-wrapper .close {
        position: absolute;
        text-align: -webkit-right;
        color: #08bcaa;
        font-size: 24px;
        width: 365px;
    }

    .center-btn {
        width: 146px;
        height: 48px;
        border-radius: 24px;
        font-size: 16px;
        margin-top: -24px;
        margin-left: 110px;
        text-align: center;
        line-height: 48px;
        overflow: hidden;
        background-color: #08bcaa;
        color: #fff;
    }

    .card-wrapper {
        margin: 29.7px 36px 36px 36px;
        padding: 10px;
        border-radius: 12px;
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: #fff;
        -webkit-overflow-scrolling: touch;
        box-shadow: 0 50px 100px rgba(0, 0, 0, .15);
        transition: all .3s ease-in-out;
    }

    .card-wrapper .header-wrapper {
        height: 160px;
        border-radius: 12px;
        background-color: aquamarine;
    }

    .card-wrapper .qrcode {
        text-align: -webkit-center;
        margin: 30px;
    }

    .point-wrapper {
        font-size: 24px;
        text-align: -webkit-center;
        font-weight: bold;
        color: #000;
    }

    .point-wrapper .point {
        font-size: 42px;
        color: #08bcaa;
    }

    .overlay-confirm,
    .overlay-main,
    .modal-overlay-footer {
        text-align: center;
    }

    .overlay-confirm {
        margin-top: -26px;
    }

    .overlay-main {
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 25px;
        line-height: 18px;
    }

    .modal-overlay-footer {
        display: flex;
        justify-content: center;
    }

    .open-pass-btn {
        width: 200px;
    }
</style>

<script>
    import Vue from 'vue';
    import QRCode from 'qrcodejs2';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import { SUCCESS_SRC } from '../../consts/const.js';
    import vModal from '../../components/Modal.vue';
    import vButton from '../../components/Button.vue';

    export default {
        name: 'v-pass-active',
        components: { vModal, vButton },
        props: {},
        data() {
            return {
                point: '0',
                SUCCESS_SRC,
                isActive: false,
                passText: 'Get my pass',
                invite_by: this.$route.query.invite_by
            }
        },
        created() {
        },
        mounted() {
            this.creatQrCode();
            this.initsInfo({data: { sid: this.$route.query.sid }})
        },
        computed: {
            ...mapGetters({
                is_loading: 'app/getLoading',
                sInfo: 'app/getsInfo',
                user: 'app/getUser',
            }),
            getPassBtnText() {
                return `${this.is_loading ? 'Getting ...' : 'Get my pass'}`;
            }
        },
        methods: {
            ...mapActions({
                activeCard: 'app/activeCard',
                initsInfo: 'app/initsInfo'
            }),
            ...mapMutations({
                updateUser: 'app/SET_USER',
                setLoading: 'app/APP_SET_IF_LOADING'
            }),
            creatQrCode() {
                let qrcode = new QRCode(this.$refs.qrCodeUrl, {
                    text: this.point,
                    width: 172,
                    height: 172,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                });
            },
            getPass() {
                if (!this.sInfo || (this.sInfo && !this.sInfo.sid) || !this.user || (this.user && !this.user.id) || this.is_loading) {
                    return;
                }
                this.setLoading({ is_loading: true });
                this.activeCard({
                    data: {
                        sid: this.sInfo.sid,
                        cid: this.user.id,
                        merchantName: `${this.sInfo.sname}`,
                        inviter_card_number: this.invite_by || ''
                    },
                    success: (check) => {
                        this.isActive = check.active;
                        this.setLoading({ is_loading: false });
                    },
                    fail: (e) => {
                        this.isActive = false;
                        this.setLoading({ is_loading: false });
                    }
                });

            },
            jumpTo() {
                this.updateUser({ home: true });
                this.$router.replace({
                    name: '/pass/detail',
                    query: { sid: this.sInfo.sid, cid: this.user.id },
                });
            },
        }
    };
</script>